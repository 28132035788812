exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-all-by-serial-number-js": () => import("./../../../src/pages/all-by-serial-number.js" /* webpackChunkName: "component---src-pages-all-by-serial-number-js" */),
  "component---src-pages-all-js": () => import("./../../../src/pages/all.js" /* webpackChunkName: "component---src-pages-all-js" */),
  "component---src-pages-choral-js": () => import("./../../../src/pages/choral.js" /* webpackChunkName: "component---src-pages-choral-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-organ-js": () => import("./../../../src/pages/organ.js" /* webpackChunkName: "component---src-pages-organ-js" */),
  "component---src-pages-organ-other-js": () => import("./../../../src/pages/organ-other.js" /* webpackChunkName: "component---src-pages-organ-other-js" */),
  "component---src-pages-organ-sounds-js": () => import("./../../../src/pages/organ-sounds.js" /* webpackChunkName: "component---src-pages-organ-sounds-js" */),
  "component---src-pages-solo-js": () => import("./../../../src/pages/solo.js" /* webpackChunkName: "component---src-pages-solo-js" */),
  "component---src-templates-recording-js": () => import("./../../../src/templates/recording.js" /* webpackChunkName: "component---src-templates-recording-js" */)
}

